<template>
    <div>
        <mobile-table>
            <table class="table">
                <thead>
                <tr>
                    <th class="col-s-10 col-xs-8">{{title}}</th>
                    <th class="col-s-2 col-xs-4 text-right">{{visits}}</th>
                </tr>
                </thead>

                <tbody>
                <tr v-for="n in limit" v-if="!hasLoaded">
                    <td :data-title="title">
                        <span class="mock">--</span>
                    </td>
                    <td :data-title="visits">
                        <span class="mock">1500 visits</span>
                    </td>
                </tr>

                <tr v-for="(item, index) in sliced" v-if="hasLoaded" :key="index">
                    <td class="stagger-animation" :data-title="title" :class="{animating, animated: !animating}">
                        <slot name="title" :item="item" :block="'block-' + index"/>
                    </td>
                    <td :data-title="visits" class="text-right">
                        <slot name="visits" :item="item" />
                    </td>
                </tr>
                </tbody>
            </table>
        </mobile-table>

        <div class="pagination-container" v-if="total">
            <pagination :total="total" :limit="limit" :page="page" @paginate="paginate" size="small" :ticks="0" :showFirstAndLast="false"/>
            <small class="text-muted current-pages">{{$t('visitors.topPages.pagination.showing')}}
                {{currentPages}} {{$t('visitors.topPages.pagination.of')}} {{total}}</small>
        </div>
    </div>
</template>
<script>
  import Card from '@/app/shared/components/Card'
  import MobileTable from '@/app/layout/components/MobileTable'
  import Pagination from '@/app/layout/components/paginator/Pagination'

  export default {
    data () {
      return {
        page: 1,
        animating: false
      }
    },
    props: {
      title: {
        type: String,
        required: true
      },
      visits: {
        type: String,
        required: true
      },
      items: {
        type: Array,
        required: true
      },
      limit: {
        type: Number,
        default: 5
      }
    },
    components: {
      Card,
      MobileTable,
      Pagination
    },
    computed: {
      total () {
        return this.items.length
      },
      hasLoaded () {
        return this.total > 0
      },
      currentPages () {
        const firstPage = ((this.page - 1) * this.limit) + 1
        const lastPage = this.limit * this.page
        return firstPage + ' - ' + lastPage
      },

      sliced () {
        setTimeout(() => {
          this.animating = false
        }, 100)

        if (this.page === 1) return this.items.slice(0, this.limit)

        const offset = ((this.page - 1) * this.limit)
        return this.items.slice(offset, offset + this.limit)
      }
    },
    methods: {
      paginate (page) {
        this.animating = true
        this.page = page
      }
    }
  }
</script>
