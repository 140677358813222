<template>
    <card icon="filter"
          :headline="$t('visitors.topReferrals.headline')"
          :description="$t('visitors.topReferrals.description')">
        <top-table class="top-pages"
                   :limit="limit"
                   :items="referrals"
                   :title="$t('visitors.topReferrals.source')"
                   :visits="$t('visitors.topReferrals.visits')"
        >
            <template #title="{item, block}">
                <div class="title animate ellipsis" :class="[ block ]">{{item.source}}</div>
                <span class="text-muted">{{item.medium}}</span>
            </template>

            <template #visits="{item}">
                {{item.sessions | numberFormat}}
            </template>
        </top-table>
    </card>
</template>

<script>
  import TopTable from '@/app/website/components/TopTable'
  import { mapGetters } from 'vuex'

  export default {
    props: {
      limit: {
        type: Number,
        default: 5
      }
    },

    computed: {
      ...mapGetters('website', {
        analyticsReferrals: 'getReferrals'
      }),

      total () {
        return this.referrals.length
      },

      hasLoaded () {
        return this.referrals.length > 0 && this.analyticsReferrals !== null
      },

      referrals () {
        if (!this.analyticsReferrals) return []

        return this.analyticsReferrals.rows.map(referral => {
          const split = referral.sourceMedium.split('/')

          referral.source = String(split[0] || '').trim()
          referral.medium = String(split[1] || '').trim()

          return referral
        })
      }
    },

    components: {
      TopTable
    }
  }
</script>
